import React from "react";
import { Row, Col, Button } from 'antd';
import InputBox from "../../Components/InputBox/InputBox";
import SelectBox from "../../Components/SelectBox/SelectBox";

class WwResidentialForm extends React.Component {

    constructor() {
        super()
        this.state = {
            select_sitemeasurement: '',
            select_size: '',
            select_budget: '',
            select_funding: '',
        };
    }
    onChangeText = (value, type) => {
        this.setState({ [type]: value })
    }

    render() {
        const sitemeasurement = [
            { label: 'Independent House', value: 1 },
            { label: 'Bungalow', value: 2 },
        ]
        const size = [
            { label: '1 acre - 10 acre', value: '1' },
            { label: '10 acre - 20 acre', value: '2' },
            { label: '20 acre - 30 acre', value: '3' },
            { label: '30 acre - 50 acre', value: '4' },
            { label: '50 acre - 100 acre', value: '5' },
        ]

        const budget = [
            { label: '20l-1cr', value: '1' },
            { label: '1cr-10cr', value: '2' },
            { label: '10cr-30cr', value: '3' },
            { label: '30cr-60cr', value: '4' },
            { label: '60cr-90cr', value: '5' },
        ]
        const funding = [
            { label: 'Self fund', value: '1' },
            { label: 'Bank Loan', value: '2' },
        ]
        return (
            <React.Fragment>
                <form>
                    <InputBox label="Name" placeholder="Enter Full Name" />
                    <InputBox label="Phone" placeholder="Enter Phone Number" />
                    <InputBox label="Email" placeholder="Enter Email" />
                    <InputBox label="Location" placeholder="Enter Location" />
                    <SelectBox label="Site measurement" placeholder="site measurement" id="select_sitemeasurement" options={sitemeasurement} value={this.state.select_sitemeasurement} onChangeText={this.onChangeText} />
                    <SelectBox label="Size" placeholder="Size" id="select_size" options={size} value={this.state.select_size} onChangeText={this.onChangeText} />
                    <SelectBox label="Budget" placeholder="Budget" id="select_budget" options={budget} value={this.state.select_budget} onChangeText={this.onChangeText} />
                    <SelectBox label="Funding" placeholder="Funding" id="select_funding" options={funding} value={this.state.select_funding} onChangeText={this.onChangeText} />
                    <Button className="btn btn-primary" type="submit">Submit</Button>
                </form>
            </React.Fragment >
        );
    }
}
export default WwResidentialForm
