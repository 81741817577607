import React from "react";
import { withRouter } from "react-router";
import Slider from "react-slick";
import "./HomeSlider.scss";
import SliderItems from "./SliderItems";

// slider component
const mainSlider = {
  arrows: true,
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5000, responsive: [
    {
      breakpoint: 1024,
      settings: {
        arrows: false,
      }
    },
    {
      breakpoint: 900,
      settings: {
        arrows: false,
      }
    },
    {
      breakpoint: 580,
      settings: {
        arrows: false,
      }
    },
    {
      breakpoint: 400,
      settings: {
        arrows: false,
      }
    }
  ]
};

class HomeSlider extends React.Component {

  render() {
    return (
      <React.Fragment>
        {/* <!--Main Slider section starts--> */}
        <div className="wwinfra-slider" id="home">
          <div className="slider-content ">
            <Slider {...mainSlider}>
              <SliderItems sliderclassName="slider-1" sliderName="Residential" />
              <SliderItems sliderclassName="slider-2" sliderName="Commercial" />
              <SliderItems sliderclassName="slider-3" sliderName="Industrial" />
              <SliderItems sliderclassName="slider-4" sliderName="Retail" />
              <SliderItems sliderclassName="slider-5" sliderName="Luxury Retail" />
              <SliderItems sliderclassName="slider-6" sliderName="Healthcare" />
              <SliderItems sliderclassName="slider-7" sliderName="Aviation" />
              <SliderItems sliderclassName="slider-8" sliderName="Marine" />
              <SliderItems sliderclassName="slider-9" sliderName="Transportation" />
              <SliderItems sliderclassName="slider-10" sliderName="Government" />
              <SliderItems sliderclassName="slider-11" sliderName="Solar" />
              <SliderItems sliderclassName="slider-12" sliderName="Wind Energy" />
              <SliderItems sliderclassName="slider-13" sliderName="Augmented" />
              <SliderItems sliderclassName="slider-14" sliderName="Virtual" />
              <SliderItems sliderclassName="slider-15" sliderName="3d" />
              <SliderItems sliderclassName="slider-16" sliderName="Robotics" />
            </Slider>
          </div>
        </div>
        {/* <!--Main Slider section ends--> */}
      </React.Fragment>
    );
  }
}
export default HomeSlider
