import React from "react";
import { Row, Col } from 'antd';
import "./OurFocusSection.scss";
import OurFocuslList from "./OurFocuslList";
import residential from "../../assets/images/our-focus/residential.jpg";
import commercial from "../../assets/images/our-focus/commercial.jpg";
import industrial from "../../assets/images/our-focus/industrial.jpg";
import retail from "../../assets/images/our-focus/retail.jpg";
import luxuryretail from "../../assets/images/our-focus/luxuryretail.jpg";
import healthcare from "../../assets/images/our-focus/healthcare.jpg";
import aviation from "../../assets/images/our-focus/aviation.jpg";
import marine from "../../assets/images/our-focus/marine.jpg";
import flyover from "../../assets/images/our-focus/flyover.jpg";
import solar from "../../assets/images/our-focus/solar.jpg";
import governmentoffice from "../../assets/images/our-focus/governmentoffice.jpg";
import windenergy from "../../assets/images/our-focus/windenergy.jpg";
import threed from "../../assets/images/our-focus/threed.jpg";
import robotics from "../../assets/images/our-focus/robotics.jpg";

class OurFocusSection extends React.Component {
    render() {
        return (
            <React.Fragment>
                <section className="section-padding  our-focus">
                    <div className="container">
                        <Row gutter={[20, 20]} className="mb-3" >
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                <div class="align-middle text-center">
                                    <h2 className="font-weight-bolder text-black title-font text-uppercase mb-2">Our Focus</h2>
                                </div>
                            </Col>
                        </Row>

                        <div className="various-ourfocus">
                            <Row gutter={[22, 22]}>
                                <OurFocuslList ourWorksImage={residential} ourWorksName="Residential" />
                                <OurFocuslList ourWorksImage={commercial} ourWorksName="Commercial" />
                                <OurFocuslList ourWorksImage={industrial} ourWorksName="Industrial" />
                                <OurFocuslList ourWorksImage={retail} ourWorksName="Retail" />
                                <OurFocuslList ourWorksImage={luxuryretail} ourWorksName="Luxury Retail" />
                                <OurFocuslList ourWorksImage={healthcare} ourWorksName="Healthcare" />
                                <OurFocuslList ourWorksImage={aviation} ourWorksName="Aviation" />
                                <OurFocuslList ourWorksImage={marine} ourWorksName="Marine" />
                                <OurFocuslList ourWorksImage={flyover} ourWorksName="Flyover" />
                                <OurFocuslList ourWorksImage={solar} ourWorksName="Solar" />
                                <OurFocuslList ourWorksImage={governmentoffice} ourWorksName="Government Offices" />
                                <OurFocuslList ourWorksImage={windenergy} ourWorksName="Wind Energy" />
                                <OurFocuslList ourWorksImage={threed} ourWorksName="3D" />
                                <OurFocuslList ourWorksImage={robotics} ourWorksName="Robotics" />
                            </Row>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}
export default OurFocusSection
