import React from "react";
import TitleSection from "../../Components/TitleSection/TitleSection";
import OurWorksSection from "./OurWorksSection";

class OurWorks extends React.Component {
  render() {
    return (
      <React.Fragment>
        <TitleSection
          welcomeText="WELCOME TO WW Infra"
          titleName="Heavy Equipments Leasing"
        />

        <OurWorksSection />
      </React.Fragment>
    );
  }
}
export default OurWorks;
