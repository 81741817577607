import React from "react";
import { withRouter } from "react-router";
import { Col } from "antd";
import "./OurWorksSection.scss";

class SlidingList extends React.Component {
    render() {
        return (
            <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 6 }}>
                <div className="item">
                    <div className="details text-center text-white">
                        <div className="image overflow-hidden border-radius">
                            <img src={`${this.props.equipmentImg}`} alt={`${this.props.equipmentName}`} className="animation-grow" />
                        </div>
                        <div className="desc">
                            <p className="text-black">{this.props.equipmentName}</p>
                        </div>
                    </div>
                </div>
            </Col>
        );
    }
}
export default withRouter(SlidingList);