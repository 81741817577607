import React from "react";
import { Row, Col } from 'antd';
import BookingForm from "./BookingForm";

class BookEquipments extends React.Component {
    render() {
        return (
            <React.Fragment>
                <section className="book-equipments section-padding">
                    <div className="container">
                        <Row gutter={[22]}>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 16 }} >
                                <h3 className="text-uppercase">Book Equipments</h3>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                               <BookingForm />
                            </Col>
                        </Row>
                    </div>
                </section>
            </React.Fragment >
        );
    }
}
export default BookEquipments
