import React from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { Row, Col } from 'antd';
import TitleSection from '../../Components/TitleSection/TitleSection';
import './ContactUs.scss';
import ContactForm from './ContactForm';

class ContactUs extends React.Component {
    render() {
        return (
            <React.Fragment>
                <TitleSection welcomeText="WELCOME TO WW Infra" titleName="Contact Us" />
                <section className="get-in-touch py-5">
                    <div className="container">
                        <Row gutter={[30, 20]} align="center" className="pb-3">
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} className="center-contents" >
                                <div className="title-section text-center mb-4">
                                         <h1 className="text-center d-inline border-left-3 pl-3  border-danger  pr-3">Contact Us</h1>
                                        <p className="mt-4">Fill out the form below and we will contact you as soon as possible.</p>
                                   
                                </div>
                            </Col>
                        </Row>

                        <Row gutter={[30, 20]} align="center" className="pb-3">
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 20 }} lg={{ span: 13 }} className="center-contents" >
                                <div className="title-section text-center mb-4">                                   
                                    <ContactForm />                                   
                                </div>
                            </Col>
                        </Row>
                    </div>
                </section>    

                            

            </React.Fragment>
        )
    }
}
export default withRouter(ContactUs)