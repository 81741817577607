import React from "react";
import TitleSection from "../../Components/TitleSection/TitleSection";
import OurFocusSection from "./OurFocusSection";

class OurFocus extends React.Component {
    render() {
        return (
            <React.Fragment>
                <TitleSection welcomeText="WELCOME TO WW Infra" titleName="Our Focus" />
                
                <OurFocusSection />
            </React.Fragment>
        );
    }
}
export default OurFocus
