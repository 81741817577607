import React from "react";
import { withRouter } from "react-router";
import { Link } from 'react-router-dom';
import { Row, Col } from 'antd';

class Technology extends React.Component {
    render() {
        return (
            <React.Fragment>
                <section className="Technology section-padding">
                    <div className="container">
                        <Row>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} />
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <div className="floating-content">
                                    <div className="vision">
                                        <div className="d-flex border-right">
                                            <div>
                                                <h2>We Are A World-class Technology Company</h2>
                                                <p>We Build Smarter Construction Solutions Advanced Technology For Lifting Materials And Automating Masonry.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </section>
            </React.Fragment >
        );
    }
}
export default Technology
