import React from "react";
import TitleSection from "../../Components/TitleSection/TitleSection";
import AboutSection from "./AboutSection";

class About extends React.Component {
    render() {
        return (
            <React.Fragment>
                <TitleSection welcomeText="WELCOME TO WW Infra" titleName="About Us" />
                
                <AboutSection />
            </React.Fragment>
        );
    }
}
export default About
