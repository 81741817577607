import React from "react";
import "./Header.scss";
import { Link } from "react-router-dom";
import { GlobalOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Space } from 'antd';
import mainlogo from "../assets/images/wwinfralogo.svg";

export let setHeader = false;

const menu = (
  <Menu
    items={[
      {
        key: '1',
        label: 'Africa'
      },
      {
        key: '2',
        label: 'America'
      },
      {
        key: '3',
        label: 'India'
      },
      {
        key: '4',
        label: 'Australia'
      },
      {
        key: '5',
        label: 'Russia'
      },
      {
        key: '6',
        label: 'Dubai'
      },
      {
        key: '7',
        label: 'Mexico'
      },
      {
        key: '8',
        label: 'UK'
      },
    ]}
  />
);


export default class Header extends React.Component {

  constructor() {
    super();
    this.state = {
      openMenu: false
    };
  }
  componentDidMount() {
    window.onscroll = function () {
      myFunction();
    };

    var header = document.getElementById("main-hearder");
    var sticky = header.offsetTop;

    function myFunction() {
      if (window.pageYOffset > sticky) {
        header.classList.add("scrolled");
      } else {
        header.classList.remove("scrolled");
      }
    }
    //Onscroll sticky Header function ends here
  }



  render() {
    return (
      <React.Fragment>
        <div className="main-hearder" id="main-hearder">
          <div className="container">
            <div className="top-header">
              <div className="logo pr-3">
                <Link to="/">
                  <img src={mainlogo} alt="GSI - Global Specialty Ingredients | Your Innovation Our Solution" />
                </Link>
              </div>
              <div className={this.state.openMenu ? "navigations opened" : "navigations"}>
                <div className="center-header">
                  <ul>
                    <li>
                      <Link exact onClick={() => this.setState({ openMenu: false })} to="/" >Home</Link>
                    </li>
                    <li>
                      <Link onClick={() => this.setState({ openMenu: false })} to="/about-us" >About us</Link>
                    </li>
                    <li>
                      <Link onClick={() => this.setState({ openMenu: false })} to="/heavy-equipments-rental-and-leasing" >Rental & Lease Equipments</Link>
                    </li>
                    <li>
                      <Link onClick={() => this.setState({ openMenu: false })} to="/our-focus" >Our Focus</Link>
                    </li>
                    <li>
                      <Dropdown onClick={() => this.setState({ openMenu: false })} overlay={menu}>
                        <a onClick={e => e.preventDefault()}>
                          <Space>
                            Countries
                            <GlobalOutlined />
                          </Space>
                        </a>
                      </Dropdown>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="contact-button">
                <Link to="./contact-us" className="btn btn-primary">Contact</Link>
              </div>
              <div id="hamburger" className={this.state.openMenu ? "menuopened" : ""} onClick={() => this.setState({ openMenu: !this.state.openMenu })} />

            </div>

            {/* bottom header starts */}

            {/* bottom header ends */}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
