import React from "react";
import { Row, Col } from 'antd';
import WwCommercialImg from "../../assets/images/wwcommercial.png";
import WwCommercialForm from "./WwCommercialForm";

class WwCommercial extends React.Component {
  render() {
    return (
      <React.Fragment>
        <section className="section-padding ww-commercial">
          <div className="container">
            <Row gutter={[20, 20]} className="mb-3" >
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                <h2 className="font-weight-bolder title-font text-uppercase mb-2">Ww Commercial</h2>
                <ul>
                  <li>3D Design and Build</li>
                  <li>Structural Drawings</li>
                  <li>Project Management</li>
                  <li>Construction Management</li>
                  <li>Design Consulting</li>
                  <li>Principal Contracting</li>
                  <li>Quality Assurance and Control</li>
                  <li>Cost Consulting</li>
                  <li>Independent Commisioning</li>
                  <li>Electrical</li>
                  <li>Plumbing</li>
                  <li>Fitouts</li>
                  <li>Green</li>
                  <li>Sustainability</li>
                </ul>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                <h2 className="font-weight-bolder title-font text-uppercase mt-2">Sectors</h2>
                <ul>
                  <li>Industrial and Warehousing</li>
                  <li>Greenfield Campus</li>
                  <li>Built to Suit Facilities</li>
                  <li>Workspaces</li>
                  <li>Independent Offices</li>
                  <li>Serviced Offices</li>
                  <li>Luxury Offices</li>
                  <li>Ecom Parks</li>
                  <li>Industrial Parks</li>
                  <li>Pharma Parks</li>
                  <li>Aero Parks</li>
                  <li>Bio Parks</li>
                  <li>Software Parks</li>
                  <li>Auto Parks</li>
                  <li>R and D Centres</li>
                  <li>Education Centres</li>
                </ul>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }}>
                <WwCommercialForm />
              </Col>
            </Row>


          </div>
        </section>
      </React.Fragment>
    );
  }
}
export default WwCommercial
