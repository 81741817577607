import React from "react";
import { Button } from 'antd';
import InputBox from "../../Components/InputBox/InputBox";
import SelectBox from "../../Components/SelectBox/SelectBox";

class WwCommercialForm extends React.Component {

    constructor() {
        super()
        this.state = {
            select_sectors: '',
            select_budget: '',
            select_funding: '',
        };
    }
    onChangeText = (value, type) => {
        this.setState({ [type]: value })
    }

    render() {
        const sectors = [
            { label: 'Stays', value: 1 },
            { label: 'Resorts', value: 2 },
            { label: 'Resotel', value: 3 },
            { label: 'Theme Parks', value: 4 },
            { label: '7 Stars', value: 5 },
            { label: '5 Stars', value: 6 },
            { label: '3 Stars', value: 7 },
            { label: 'Boutique Hotels', value: 8 },
            { label: 'Serviced Hotels', value: 9 },
            { label: 'Motels', value: 10 },
            { label: 'Casino', value: 11 },
            { label: 'Night Clubs', value: 12 }
        ]

        const budget = [
            { label: '50cr-100cr', value: '1' },
            { label: '200cr-500cr', value: '2' },
            { label: '1000cr-5000cr', value: '3' },
        ]
        const funding = [
            { label: 'InProgress', value: '1' },
            { label: 'Ready', value: '2' },
        ]
        return (
            <React.Fragment>
                <form>
                    <InputBox label="Organization Name" placeholder="Enter Organization Name" />
                    <InputBox label="Phone" placeholder="Enter Phone Number" />
                    <InputBox label="Email" placeholder="Enter Email" />
                    <InputBox label="Location" placeholder="Enter Location" />
                    <SelectBox label="Sectors" placeholder="Sectors" id="select_sectors" options={sectors} value={this.state.select_sectors} onChangeText={this.onChangeText} />
                    <SelectBox label="Budget" placeholder="Budget" id="select_budget" options={budget} value={this.state.select_budget} onChangeText={this.onChangeText} />
                    <SelectBox label="Funding" placeholder="Funding" id="select_funding" options={funding} value={this.state.select_funding} onChangeText={this.onChangeText} />
                    <InputBox type="date" label="Start Date" placeholder="Start Date" />
                    <InputBox type="date" label="End Date" placeholder="End Date" />
                    <Button className="btn btn-primary" type="submit">Submit</Button>
                </form>
            </React.Fragment >
        );
    }
}
export default WwCommercialForm
