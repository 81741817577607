import React from "react";
import { withRouter } from "react-router";

class SliderItems extends React.Component {
    render() {
        return (
            <div className={`${this.props.sliderclassName} items` } >
                <div className="center-contents w-100 p-mob">
                    <div className=" middle-content ">
                        <div className="align-middle slider-caption">
                            <h1 className="font-weight-normal text-white text-center  mb-3">WELCOME TO WW INFRA</h1>
                            <p className="text-white text-center text-uppercase ">{this.props.sliderName}</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default withRouter(SliderItems);