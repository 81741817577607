import React from "react";
import { withRouter } from "react-router";
import { Col } from "antd";

class Propertyist extends React.Component {
    render() {
        return (
            <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 4 }}>
                <div className="details border p-3">
                <img src={`${this.props.propertyImage}`} alt={`${this.props.propertyName}`} />
                <h3>{this.props.PropertyCount}</h3>
                <p>{this.props.propertyName}</p>
                </div>
            </Col>
        );
    }
}
export default withRouter(Propertyist);