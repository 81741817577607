import React from "react";
import { withRouter } from "react-router";
import { Link } from 'react-router-dom';
import { Row, Col } from 'antd';
import aboutImage from "../../assets/images/about.jpg"
import "./About.scss";

class AboutSection extends React.Component {
  render() {
    return (
      <React.Fragment>
        <section className="about-wwinfra section-padding" id="aboutus">
          <div className="container">
            <Row gutter={[22, 22]}>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                <img src={aboutImage} alt="WW INFRA" />
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                <div class="px-lg-3 align-middle">
                  <h4>About Us</h4>
                  <p>WW INFRA is changing the way the old Methodology of Construction is done with legacy tools and skilled workforce.</p>
                  <p>WW INFRA today is adopting latest 3D Printer Technology for Designbuild, Robots for Construction and AI for Robust Infrastructure.</p>
                  <p>WW INFRA has partnered with leading Construction and Mining Companies to develop latest equipments for better Construction and Infrastructure. </p>
                  <p>WW INFRA has been in infrastructure for over 50 years and Built Bridges, Flyovers, Commercial, Government, Serviced Offices and had worked in sectors in Hospitality, Healthcare, Aviation and Marine.</p>
                </div>
              </Col>
              
            </Row>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
export default AboutSection
