import React from 'react';
import { Link } from "react-router-dom";
import { Row, Col, BackTop } from 'antd';
import './Footer.scss'
import mainlogo from "../assets/images/footer-logo.jpeg";
import moveTop from "../assets/images/up-arrow.svg"
export default class Footer extends React.Component {

    render() {
        return (
            <React.Fragment>
                <section className="footer py-5" id="contact">
                    <div className="container">
                        <Row>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                <div className="footer-logo mb-3 mb-lg-5">
                                    <img src={mainlogo} className="pr-4 mr-4" alt="WW Infra" />
                                </div>
                                <div className="social-media">
                                    <h3 className="text-red text-uppercase">Follow us</h3>
                                    <ul className="d-flex p-0 m-0">
                                        <li><Link to="#" target="_blank" rel="noopener noreferrer"><i className="fa fa-facebook" aria-hidden="true" /></Link></li>
                                        <li><Link to="#" target="_blank" rel="noopener noreferrer"><i className="fa fa-instagram" aria-hidden="true" /></Link></li>
                                        <li><Link to="#" target="_blank" rel="noopener noreferrer"><i className="fa fa-twitter" aria-hidden="true" /></Link></li>
                                        <li><Link to="#" target="_blank" rel="noopener noreferrer"><i className="fa fa-linkedin-square" aria-hidden="true" /></Link></li>
                                    </ul>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                <div class="resfooter">
                                    <h3>WW INFRA</h3>
                                    <ul>
                                        <li><Link to="#">Residential</Link></li>
                                        <li><Link to="#">Commercial</Link></li>
                                        <li><Link to="#">Industrial</Link></li>
                                        <li><Link to="#">Retail</Link></li>
                                        <li><Link to="#">Luxury Retail</Link></li>
                                        <li><Link to="#">Healthcare</Link></li>
                                        <li><Link to="#">Aviation</Link></li>
                                    </ul>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                <div class="resfooter">
                                    <h3>WW INFRA</h3>
                                    <ul>
                                        <li><Link to="#">Marine</Link></li>
                                        <li><Link to="#">Transportation</Link></li>
                                        <li><Link to="#">Government and Public</Link></li>
                                        <li><Link to="#">Green Sustainability</Link></li>
                                        <li><Link to="#">Solar</Link></li>
                                        <li><Link to="#">Wind Energy</Link></li>
                                        <li><Link to="#">Minerals</Link></li>
                                    </ul>
                                </div>
                            </Col>

                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                <div class="resfooter">
                                    <h3>More Links</h3>
                                    <ul>
                                        <li><Link to="/about-us">About Us</Link></li>
                                        <li><Link to="#">Career</Link></li>
                                        <li><Link to="/terms-and-conditions">Terms and Condition</Link></li>
                                        <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                                        <li><Link to="#">Copyright Policy</Link></li>
                                    </ul>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                <div className="contact-item mt-sm-2 mt-md-3 mt-lg-4">
                                    <div className="icon">
                                        <i className="fa fa-envelope" aria-hidden="true" />
                                    </div>
                                    <div className="details">
                                        <span>Email us</span>
                                        <p><a href="mailto:info@wwinfra.com">info@wwinfra.com</a></p>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                <div className="contact-item  mt-sm-2 mt-md-3 mt-lg-4">
                                    <div className="icon">
                                        <i className="fa fa-clock-o" aria-hidden="true" />
                                    </div>
                                    <div className="details">
                                        <span>Hours</span>
                                        <p className="text-white">Mon-Fri 9:00AM - 6:00 PM (IST) </p>
                                    </div>
                                </div>
                            </Col>

                        </Row>
                        <Row className="text-center pt-3 border-t mt-3 locations">
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                <p className="text-white text-center">
                                    <span>Bengaluru</span>
                                    <span>Hyderabad</span>
                                    <span>Chennai</span>
                                    <span>Kochi</span>
                                    <span>Mumbai</span>
                                    <span>Pune</span>
                                    <span>Gurgaon</span>
                                    <span>Goa</span>
                                    <span>Dubai</span>
                                </p>
                            </Col>
                        </Row>
                        <Row className="text-center pt-3 border-t mt-3">
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                <p className="text-white mb-0 pt-3"><span>© {(new Date().getFullYear())} Copyright WW INFRA. All Right Reserved.</span></p>
                            </Col>
                        </Row>
                    </div>
                </section>

                <BackTop>
                    <div className="back-to-top">
                        <img src={moveTop} alt="move to top" />
                        <span className="d-block">UP</span>
                    </div>
                </BackTop>
            </React.Fragment>
        );
    }
}