import React from "react";
import { withRouter } from "react-router";
import { Col } from 'antd';

class OurFocuslList extends React.Component {
    render() {
        return (
            <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 6 }} >
            <div className="ourfocus-card border-radius">
                <div className="image">
                    <img src={`${this.props.ourWorksImage}`} alt={`${this.props.ourWorksName}`} />
                </div>
                <div className="content text-center">
                    <p className="text-black">{this.props.ourWorksName}</p>
                </div>
            </div>
            </Col>
        );
    }
}
export default withRouter(OurFocuslList);