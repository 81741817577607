import React from "react";
import { withRouter } from "react-router";
import { Row, Col } from "antd";
import Propertyist from "./Propertyist";

import Residential from "../../../assets/images/completed-project/residential.png";
import Commercial from "../../../assets/images/completed-project/commercial.png";
import Hospitality from "../../../assets/images/completed-project/hospitality.png";
import Healthcare from "../../../assets/images/completed-project/healthcare.png";
import Government from "../../../assets/images/completed-project/government.png";
import Retail from "../../../assets/images/completed-project/retail.png";

class PropertySize extends React.Component {

    render() {
        return (
            <React.Fragment>
                <section className="completed-project section-padding" id="ourwork">
                    <div className="container">
                        <Row gutter={[30, 20]} align="center" className="text-white pb-4 ">
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} >
                                <h2 className="text-center text-uppercase title-font">Completed Projects</h2>
                            </Col>
                        </Row>
                        <Row gutter={[20, 20]} align="center" className="pb-4 text-center">
                           <Propertyist propertyImage={Residential} propertyName="Residential" PropertyCount="100" />
                           <Propertyist propertyImage={Commercial} propertyName="Commercial" PropertyCount="50" />
                           <Propertyist propertyImage={Hospitality} propertyName="Hospitality" PropertyCount="20" />
                           <Propertyist propertyImage={Healthcare} propertyName="Healthcare" PropertyCount="5" />
                           <Propertyist propertyImage={Government} propertyName="Government" PropertyCount="100" />
                           <Propertyist propertyImage={Retail} propertyName="Retail" PropertyCount="10" />
                        </Row>
                    </div>
                </section>


            </React.Fragment>
        );
    }
}
export default withRouter(PropertySize);
