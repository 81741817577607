import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from 'react-router-dom';
import Home from '../Screens/Home/Home';
import Layout from '../Layout/Layout';
import About from  '../Screens/About/About';
import OurWorks from  '../Screens/OurWorks/OurWorks';
import OurFocus from  '../Screens/OurFocus/OurFocus';
import TermsandConditions from  '../Screens/TermsandConditions/TermsandConditions';
import PrivacyPolicy from  '../Screens/PrivacyPolicy/PrivacyPolicy';
import ContactUs from  '../Screens/ContactUs/ContactUs';
class InnerRoutes extends React.Component {
    render() {
        const {
            match: { url },
        } = this.props;
        return (
            <Layout {...this.props}>
                <Switch>
                    <Route exact path={`/`} component={Home} />
                    <Route path="/about-us" component={About} />
                    <Route path="/heavy-equipments-rental-and-leasing" component={OurWorks} />
                    <Route path="/our-focus" component={OurFocus} />
                    <Route path="/terms-and-conditions" component={TermsandConditions} />
                    <Route path="/privacy-policy" component={PrivacyPolicy} />
                    <Route path="/contact-us" component={ContactUs} />
                </Switch>
            </Layout>
        );
    }
}

export default InnerRoutes;
