import React from "react";
import { withRouter } from "react-router";
import { Link } from 'react-router-dom';
import { Row, Col, Select, Button } from 'antd';
import InputBox from "../../../Components/InputBox/InputBox";
import SelectBox from "../../../Components/SelectBox/SelectBox";

class BookingForm extends React.Component {

    constructor() {
        super()
        this.state = {
            select_equipments: '',
            select_category: '',
            select_duration: '',
            select_purchase: '',
            select_budget: '',
            select_funding: '',
        };
    }
    onChangeText = (value, type) => {
        this.setState({ [type]: value })
    }

    render() {
        const equipments = [
            { label: 'Escavator', value: 1 },
            { label: 'Trenchers', value: 2 },
            { label: 'Telehandlers', value: 3 },
            { label: 'Power Shovel', value: 4 },
            { label: 'Tower Cranes', value: 5 },
            { label: 'Surface Vibrator', value: 6 },
            { label: 'Demoltion Robot', value: 7 },
            { label: 'Ripper', value: 8 },
            { label: 'Platform Vibrators', value: 9 },
            { label: 'Pile Driving', value: 10 },
            { label: 'Pile Boring', value: 11 },
            { label: 'Mobile Cranes', value: 12 },
            { label: 'Feller Bunchers', value: 13 },
            { label: 'Hoist Elevators', value: 14 },
            { label: 'Graders', value: 15 },
            { label: 'Form Vibrator', value: 16 },
            { label: 'Dump Truck', value: 17 },
            { label: 'Concrete Chuters', value: 18 },
            { label: 'Aggregate sharing', value: 19 },
            { label: 'Compactor', value: 20 },
            { label: 'Backhoe', value: 21 },
            { label: 'Loaders', value: 22 },
            { label: 'Aggregate screening', value: 23 },
            { label: 'Wheel Tracktor Scrapers', value: 24 },
        ]
        const category = [
            { label: 'Residential', value: 'residential' },
            { label: 'Commercial', value: 'commercial' },
            { label: 'Hospitality', value: 'hospitality' },
            { label: 'Retail', value: 'retail' },
            { label: 'Healthcare', value: 'healthcare' },
        ]
        const duration = [
            { label: '3 Months', value: '1' },
            { label: '6 Months', value: '2' },
            { label: '1 year', value: '3' },
        ]
        const purchase = [
            { label: 'Lease', value: '1' },
            { label: 'Rent', value: '2' },
        ]
        const budget = [
            { label: '20l-1cr', value: '1' },
            { label: '1cr-10cr', value: '2' },
            { label: '10cr-30cr', value: '3' },
            { label: '30cr-60cr', value: '4' },
            { label: '60cr-90cr', value: '5' },
        ]
        const funding = [
            { label: 'Self fund', value: '1' },
            { label: 'Bank Loan', value: '2' },
        ]
        return (
            <React.Fragment>
                <form>
                    <InputBox label="Name" placeholder="Enter Full Name" />
                    <InputBox label="Phone" placeholder="Enter Phone Number" />
                    <InputBox label="Email" placeholder="Enter Email" />
                    <InputBox label="Location" placeholder="Enter Location" />
                    <SelectBox label="Equipments" placeholder="Equipments" id="select_equipments" options={equipments} value={this.state.select_equipments} onChangeText={this.onChangeText} />
                    <SelectBox label="Category" placeholder="Category" id="select_category" options={category} value={this.state.select_catgeory} onChangeText={this.onChangeText} />
                    <SelectBox label="Duration" placeholder="Duration" id="select_duration" options={duration} value={this.state.select_duration} onChangeText={this.onChangeText} />
                    <SelectBox label="Purchase" placeholder="Purchase" id="select_purchase" options={purchase} value={this.state.select_purchase} onChangeText={this.onChangeText} />
                    <SelectBox label="Budget" placeholder="Budget" id="select_budget" options={budget} value={this.state.select_budget} onChangeText={this.onChangeText} />
                    <SelectBox label="Funding" placeholder="Funding" id="select_funding" options={funding} value={this.state.select_funding} onChangeText={this.onChangeText} />
                    <Button className="btn btn-primary" type="submit">Submit</Button>
                </form>
            </React.Fragment >
        );
    }
}
export default BookingForm
