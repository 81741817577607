import React from "react";
import { Row, Col } from 'antd';
import HomeSlider from "./HomeSlider/HomeSlider";
import AboutSection from "../About/AboutSection";
import OurWorksSection from "../OurWorks/OurWorksSection";
import WwResidential from "../WwResidential/WwResidential";
import WwCommercial from "../WwCommercial/WwCommercial";
import OurFocusSection from "../OurFocus/OurFocusSection";
import Technology from "./Technology/Technology";
import BookEquipments from "./BookEquipments/BookEquipments";
import WwHospitality from "../WwHospitality/WwHospitality";
import PropertySize from "./PropertySize/PropertySize";

import "./Home.scss";


export default class Home extends React.Component {

  handlePageChange = number => {
    this.setState({ currentPage: number });
  };

  handleBeforePageChange = number => {
    console.log(number);
  };
  render() {
    return (
      <React.Fragment>
        <HomeSlider />
        <div className="bg-light-gray">
          <PropertySize />
        </div>

        <AboutSection />

        <Technology />

        <OurWorksSection />

        <BookEquipments />
        <div className="mt-5" />
        <WwResidential />
        <div className="mt-5" />

        <WwCommercial />
        <div className="mt-5" />
        <WwHospitality />
        <div className="bg-light-gray">
          <OurFocusSection />
        </div>
      </React.Fragment>
    );
  }
}
