import React from "react";
import { withRouter } from "react-router";
import { Row, Col } from "antd";
import SlidingList from "./SlidingList";
import "./OurWorksSection.scss";
import Excavator from "../../assets/images/our-works/excavator.jpg";
import Trenchers from "../../assets/images/our-works/trenchers.jpg";
import Telehandlers from "../../assets/images/our-works/telehandlers.jpg";
import powerShovel from "../../assets/images/our-works/power-shovel.jpg";
import towerCranes from "../../assets/images/our-works/tower-cranes.jpg";
import surfaceVibrator from "../../assets/images/our-works/surface-vibrator.jpg";
import demoltionRobot from "../../assets/images/our-works/demoltion-robot.jpg";
import Ripper from "../../assets/images/our-works/ripper.jpg";
import platformVibrators from "../../assets/images/our-works/platform-vibrators.jpg";
import pileDriving from "../../assets/images/our-works/pile-driving.jpg";
import pileBoring from "../../assets/images/our-works/pile-boring.jpg";
import mobileCranes from "../../assets/images/our-works/mobile-cranes.jpg";
import fellerBunchers from "../../assets/images/our-works/feller-bunchers.jpg";
import hoistElevators from "../../assets/images/our-works/hoist-elevators.jpg";
import Graders from "../../assets/images/our-works/graders.jpg";
import formVibrator from "../../assets/images/our-works/form-vibrator.jpg";
import dumpTruck from "../../assets/images/our-works/dump-truck.jpg";
import concreteChuters from "../../assets/images/our-works/concrete-chuters.jpg";
import aggregateSharing from "../../assets/images/our-works/aggregate-sharing.jpg";
import Compactor from "../../assets/images/our-works/compactor.jpg";
import Backhoe from "../../assets/images/our-works/backhoe.jpg";
import Loaders from "../../assets/images/our-works/loaders.jpg";
import aggregateScreening from "../../assets/images/our-works/aggregate-screening.jpg";
import wheelTracktorScrapers from "../../assets/images/our-works/wheel-tracktor-scrapers.jpg";

class OurWorksSection extends React.Component {
  render() {
    return (
      <React.Fragment>
        <section className="event-gallery section-padding" id="ourwork">
          <div className="container">
            <Row gutter={[30, 20]} align="center" className="text-white pb-4 ">
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
              >
                <h2 className="text-center text-uppercase title-font">
                  WW Construction and Heavy Equipments Leasing
                </h2>
              </Col>
            </Row>
            <Row gutter={[20, 20]} align="center" className="pb-4">
              <SlidingList equipmentImg={Excavator} equipmentName="Escavator" />
              <SlidingList equipmentImg={Trenchers} equipmentName="Trenchers" />
              <SlidingList
                equipmentImg={Telehandlers}
                equipmentName="Telehandlers"
              />
              <SlidingList
                equipmentImg={powerShovel}
                equipmentName="Power Shovel"
              />
              <SlidingList
                equipmentImg={towerCranes}
                equipmentName="Tower Cranes"
              />
              <SlidingList
                equipmentImg={surfaceVibrator}
                equipmentName="Surface Vibrator"
              />
              <SlidingList
                equipmentImg={demoltionRobot}
                equipmentName="Demoltion Robot"
              />
              <SlidingList equipmentImg={Ripper} equipmentName="Ripper" />
              <SlidingList
                equipmentImg={platformVibrators}
                equipmentName="Platform Vibrators"
              />
              <SlidingList
                equipmentImg={pileDriving}
                equipmentName="Pile Driving"
              />
              <SlidingList
                equipmentImg={pileBoring}
                equipmentName="Pile Boring"
              />
              <SlidingList
                equipmentImg={mobileCranes}
                equipmentName="Mobile Cranes"
              />
              <SlidingList
                equipmentImg={fellerBunchers}
                equipmentName="Feller Bunchers"
              />
              <SlidingList
                equipmentImg={hoistElevators}
                equipmentName="Hoist Elevators"
              />
              <SlidingList equipmentImg={Graders} equipmentName="Graders" />
              <SlidingList
                equipmentImg={formVibrator}
                equipmentName="Form Vibrator"
              />
              <SlidingList
                equipmentImg={dumpTruck}
                equipmentName="Dump Truck"
              />
              <SlidingList
                equipmentImg={concreteChuters}
                equipmentName="Concrete Chuters"
              />
              <SlidingList
                equipmentImg={aggregateSharing}
                equipmentName="Aggregate sharing"
              />
              <SlidingList equipmentImg={Compactor} equipmentName="Compactor" />
              <SlidingList equipmentImg={Backhoe} equipmentName="Backhoe" />
              <SlidingList equipmentImg={Loaders} equipmentName="Loaders" />
              <SlidingList
                equipmentImg={aggregateScreening}
                equipmentName="Aggregate screening"
              />
              <SlidingList
                equipmentImg={wheelTracktorScrapers}
                equipmentName="Wheel Tracktor Scrapers"
              />
            </Row>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
export default withRouter(OurWorksSection);
