import React from "react";
import {Button } from 'antd';
import InputBox from "../../Components/InputBox/InputBox";
import SelectBox from "../../Components/SelectBox/SelectBox";

class WwCommercialForm extends React.Component {

    constructor() {
        super()
        this.state = {
            select_service: '',
            select_sectors: '',
            select_budget: '',
            select_funding: '',
        };
    }
    onChangeText = (value, type) => {
        this.setState({ [type]: value })
    }

    render() {
        const service = [
            { label: '3D Design and Build', value: 1 },
            { label: 'Structural Drawings', value: 2 },
            { label: 'Project Management', value: 3 },
            { label: 'Construction Management', value: 4 },
            { label: 'Design Consulting', value: 5 },
            { label: 'Principal Contracting', value: 6 },
            { label: 'Quality Assurance and Control', value: 7 },
            { label: 'Cost Consulting', value: 8 },
            { label: 'Independent Commisioning', value: 9 },
            { label: 'Electrical', value: 10 },
            { label: 'Plumbing', value: 11 },
            { label: 'Fitouts', value: 12 },
            { label: 'Green', value: 13 },
            { label: 'Sustainability', value: 14 }
        ]
        const sectors = [
            { label: 'Industrial and Warehousing', value: 1 },
            { label: 'Greenfield Campus', value: 2 },
            { label: 'Built to Suit Facilities', value: 3 },
            { label: 'Workspaces', value: 4 },
            { label: 'Independent Offices', value: 5 },
            { label: 'Serviced Offices', value: 6 },
            { label: 'Luxury Offices', value: 7 },
            { label: 'Ecom Parks', value: 8 },
            { label: 'Industrial Parks', value: 9 },
            { label: 'Pharma Parks', value: 10 },
            { label: 'Aero Parks', value: 11 },
            { label: 'Bio Parks', value: 12 },
            { label: 'Software Parks', value: 13 },
            { label: 'Auto Parks', value: 14 },
            { label: 'R and D Centres', value: 15 },
            { label: 'Education Centres', value: 16 }
        ]

        const budget = [
            { label: '50cr-100cr', value: '1' },
            { label: '200cr-500cr', value: '2' },
            { label: '1000cr-5000cr', value: '3' },
        ]
        const funding = [
            { label: 'InProgress', value: '1' },
            { label: 'Ready', value: '2' },
        ]
        return (
            <React.Fragment>
                <form>
                    <InputBox label="Organization Name" placeholder="Enter Organization Name" />
                    <InputBox label="Phone" placeholder="Enter Phone Number" />
                    <InputBox label="Email" placeholder="Enter Email" />
                    <InputBox label="Location" placeholder="Enter Location" />
                    <SelectBox label="Service" placeholder="Service" id="select_service" options={service} value={this.state.select_service} onChangeText={this.onChangeText} />
                    <SelectBox label="Sectors" placeholder="Sectors" id="select_sectors" options={sectors} value={this.state.select_sectors} onChangeText={this.onChangeText} />
                    <SelectBox label="Budget" placeholder="Budget" id="select_budget" options={budget} value={this.state.select_budget} onChangeText={this.onChangeText} />
                    <SelectBox label="Funding" placeholder="Funding" id="select_funding" options={funding} value={this.state.select_funding} onChangeText={this.onChangeText} />
                    <InputBox type="date" label="Start Date" placeholder="Start Date" />
                    <InputBox type="date" label="End Date" placeholder="End Date" />
                    <Button className="btn btn-primary" type="submit">Submit</Button>
                </form>
            </React.Fragment >
        );
    }
}
export default WwCommercialForm
