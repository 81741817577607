import React from "react";
import { Row, Col } from 'antd';
import "./WwResidential.scss";
import wwresidentialImg from "../../assets/images/wwresidential.jpg";
import WwResidentialForm from "./WwResidentialForm";

class WwResidential extends React.Component {
  render() {
    return (
      <React.Fragment>
        <section className="section-padding ww-residential" id="WwResidential">
          <div className="container">
            <Row gutter={[20, 20]} className="mb-3" >
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 16 }}>
                <div class="align-middle">
                  <h2 className="font-weight-bolder title-font text-uppercase mb-2">Ww Residential</h2>
                  <ul>
                    <li>3D Design and Build</li>
                    <li>Structural Drawings</li>
                    <li>Kitchen</li>
                    <li>Master Bedroom</li>
                    <li>Guest Bedroom</li>
                    <li>Bathroom</li>
                    <li>Garden</li>
                    <li>Electrical</li>
                    <li>Plumbing</li>
                    <li>Cladding</li>
                    <li>Doors and Windows</li>
                    <li>False Ceiling</li>
                    <li>Costings and Budget</li>
                    <li>Plan</li>
                    <li>Execute</li>
                    <li>360 Quality Checks</li>
                    <li>Builder Audits</li>
                    <li>Handover</li>
                  </ul>
                </div>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                <WwResidentialForm />
              </Col>
            </Row>


          </div>
        </section>
      </React.Fragment>
    );
  }
}
export default WwResidential
