import React from "react";
import { Row, Col } from 'antd';
import WwHospitalityImg from "../../assets/images/wwhospitality.jpg";
import WwHospitalityForm from "./WwHospitalityForm";

class WwHospitality extends React.Component {
  render() {
    return (
      <React.Fragment>
        <section className="section-padding ww-hospitality">
          <div className="container">
            <Row gutter={[20, 20]} className="mb-3" >
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                <h2 className="font-weight-bolder title-font text-uppercase mb-2">Ww Hospitality</h2>
                <ul>
                  <li>Biplab 3D</li>
                  <li>Lobby</li>
                  <li>Guest Rooms</li>
                  <li>Restaurants</li>
                  <li>Spa and Wellness Centers</li>
                  <li>VIP Floors</li>
                  <li>Interiors and Fitouts</li>
                  <li>Guest Lounges</li>
                  <li>Garden and Golf Course</li>
                  <li>Electrical</li>
                  <li>Plumbing</li>
                  <li>Fire and Safety Technology</li>
                </ul>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                <h2 className="font-weight-bolder title-font text-uppercase mt-2">Sectors</h2>
                <ul>
                  <li>Stays</li>
                  <li>Resorts</li>
                  <li>Resotel</li>
                  <li>Theme Parks</li>
                  <li>7 Stars</li>
                  <li>5 Stars</li>
                  <li>3 Stars</li>
                  <li>Boutique Hotels</li>
                  <li>Serviced Hotels</li>
                  <li>Motels</li>
                  <li>Casino</li>
                  <li>Night Clubs</li>
                </ul> 

              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }}>
                <WwHospitalityForm />
              </Col>
            </Row>


          </div>
        </section>
      </React.Fragment>
    );
  }
}
export default WwHospitality
